var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"elevation-3 d-flex align-center",attrs:{"height":_vm.$vuetify.breakpoint.mobile ? 70 : 100}},[_c('v-container',[_c('v-row',{class:_vm.$vuetify.breakpoint.mobile ? 'px-5 pr-8' : '',attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2","md":"1"}},[_c('v-img',{attrs:{"src":require("@/assets/logo.png"),"max-width":_vm.$vuetify.breakpoint.xl ? '50%' : '80%'}})],1),(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"5"}},[_c('ul',{staticClass:"d-flex align-center"},_vm._l((_vm.items),function(item,idx){return _c('li',{key:idx,staticClass:"mx-3 text-lg-body-2 text-xl-h6"},[(idx == 1)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-click":"","min-width":"200","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({class:`${
                    _vm.$route.path == item.route
                      ? 'primary--text'
                      : 'secondary--text'
                  }`},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.title)+" "),_c('span',[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-chevron-down")])],1)])]}}],null,true)},[_c('v-card',[_c('v-treeview',{attrs:{"items":_vm.allCategories,"open-on-click":"","item-key":"name","hoverable":"","item-children":"categories_all","dense":""},scopedSlots:_vm._u([{key:"label",fn:function({ item }){return [(item.type === 1 && item.subcategories.length > 0)?_c('v-menu',{attrs:{"open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"dense":""}},'v-list-item',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" fas fa-chevron-right ")])],1)]}}],null,true)},[_c('v-card',[_c('v-list',_vm._l((item.subcategories),function(item,idx){return _c('v-list-item',{key:idx,attrs:{"dense":""},on:{"click":function($event){return _vm.setProductsCategorySearch(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1):(item.id === 'allProducts')?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.$router.push('/products')}}},[_vm._v(" "+_vm._s(item.name)+" ")]):(
                        typeof item.id === 'string' &&
                        item.id !== 'allProducts'
                      )?_c('v-list-item',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.setProductsCategorySearch(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})],1)],1):_c('router-link',{class:`${
                _vm.$route.path == item.route
                  ? 'primary--text'
                  : 'secondary--text'
              }`,attrs:{"to":item.route}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0)]):_c('v-navigation-drawer',{staticClass:"pt-3",attrs:{"absolute":"","temporary":""},model:{value:(_vm.$store.state.siteMenuDrawer),callback:function ($$v) {_vm.$set(_vm.$store.state, "siteMenuDrawer", $$v)},expression:"$store.state.siteMenuDrawer"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{class:`${
              _vm.$route.path == '/' ? 'primary--text' : 'secondary--text'
            }`,attrs:{"link":"","to":"/"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Início")])],1)],1),_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Produtos")])]},proxy:true}])},[_c('v-list-item',{attrs:{"to":"/products"}},[_c('v-list-item-title',[_vm._v("Todos os produtos")])],1),_c('v-list-group',{attrs:{"sub-group":"","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Por Categoria")])]},proxy:true}])},_vm._l((_vm.mainCategories),function(content,idx){return _c('v-list-item',{key:idx,attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"pl-3 secondary--text text--lighten-1"},[_vm._v(" "+_vm._s(content)+" ")])],1)}),1),_c('v-list-group',{attrs:{"sub-group":"","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Por Segmentos")])]},proxy:true}])},_vm._l((_vm.segments),function(content,idx){return _c('v-list-item',{key:idx,attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"pl-3 secondary--text text--lighten-1"},[_vm._v(_vm._s(content))])],1)}),1),_c('v-list-group',{attrs:{"sub-group":"","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Por Datas Especiais")])]},proxy:true}])},_vm._l((_vm.specialDates),function(content,idx){return _c('v-list-item',{key:idx,attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"pl-3 secondary--text text--lighten-1"},[_vm._v(_vm._s(content))])],1)}),1)],1),_vm._l((_vm.items.filter(
              (i) => i.route != '/' && i.route != '/products'
            )),function(item){return _c('v-list-item',{key:item.title,class:`${
              _vm.$route.path == item.route ? 'primary--text' : 'secondary--text'
            }`,attrs:{"link":"","to":item.route}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)})],2)],1),(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{staticClass:"pa-0 d-flex align-center",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"placeholder":"Buscar produtos","append-icon":"fas fa-search","hide-details":"","dense":"","outlined":"","rounded":""}}),(_vm.is_logged == false)?_c('span',{staticClass:"ml-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.updateDialog}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-user-circle")])],1)],1):_c('span',{staticClass:"ml-2"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({attrs:{"rounded":"","outlined":"","color":"primary"}},'v-chip',attrs,false),on),[_vm._v(" Olá, "+_vm._s(_vm.$cookies.get('name'))+" ")])]}}],null,false,2796638337)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":_vm.goToAdmin}},[_vm._v(" Meu perfil ")]),_c('v-list-item',{on:{"click":_vm.logout}},[_vm._v(" Sair ")])],1)],1)],1)],1):_vm._e(),_c('v-col',{staticClass:"pa-0",attrs:{"align":"end","cols":"1"}},[_c('v-badge',{attrs:{"color":"accent","content":_vm.countProducts,"overlap":"","bordered":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push('/budget/cart')}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-shopping-cart")])],1)],1)],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"max-width":_vm.$vuetify.breakpoint.xl ? '20%' : '30%'},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('LoginModal')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }